.image-container {
    position: relative;
    display: inline-block; /* Ensure container fits content */
  }
  
  .overlay-button {
    position: absolute;
    top: 5%;
    left: 25%;
    transform: translate(-50%, -50%);
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .overlay-Image {
    position: absolute;
    top: 100%;
    left: 25%;
    transform: translate(-50%, -50%);
    padding: 10px 20px;
    background-color: white;
    color: white;
    border: none;
    border-radius: 5px;
   
  }
  .overlay-Advertisement {
    position: absolute;
    top: 15%;
    left: 82%;
    transform: translate(-50%, -50%);
    padding: 5px 10px;
    background-color: white;
    color: white;
    border: none;
    border-radius: 5px;
  }
  
  .image {
    display: block; /* Ensure image doesn't have extra space */
    width: 60%;
    height: auto;
  }