.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto; /* Enable vertical scrolling if needed */
    z-index: 1000;
  }
  
  .modalContent {
    background: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 90%; /* Adjust the maximum width as needed */
    max-height: 90%; /* Adjust the maximum height as needed */
    overflow: auto; /* Enable scrolling within the modal content if needed */
  }
  
  .documentContent {
    margin-top: 20px;
    
  }